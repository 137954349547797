// Status Codes for API Calls
import {
  AGREE_TERMS,
  UNKNOWN_ERR
} from "../constants/constants";

// POST/guest API status codes
export const guestAuthResponses = (error, config) => ({
  400: {
    severity: "error",
    response: "Institution not found"
  },
  403: {
    severity: "error",
    response: "We could not verify your information. Please try again."
  },
  404: {
    severity: "error",
    response: `We're unable to access your information. Please contact ${config?.brand_name} at ${config?.brand_phone}.`
  },
  502: {
    severity: "error",
    response: "An unexpected connection error occurred. Please try back later."
  },
  503: {
    severity: "warning",
    response: error ? error?.data?.errorMessage?.message : "Temporarily Offline for Maintenance"
  },
  504: {
    severity: "error",
    response: "An unexpected connection error occurred. Please try back later."
  }
});

// Link Bank Account
export const bankInfoStatusCodes = () => ({
  400: {
    severity: "error",
    response: "We could not verify your routing number. Please enter a valid routing number and try again."
  },
  403: {
    severity: "error",
    response: "We could not verify your information. Please try again."
  },
  404: {
    severity: "error",
    response: "Routing Number from this Financial Institution is not found."
  },
  502: {
    severity: "error",
    response: "An unexpected connection error occurred. Please try back later."
  },
  504: {
    severity: "error",
    response: "An unexpected connection error occurred. Please try back later."
  }
});

export const userChangeEmail = () => ({
  200: {
    severity: "success",
    response: "Email has been changed successfully!"
  },
  409: {
    severity: "warning",
    response: "User with that email already exists."
  },
  504: {
    severity: "error",
    response: "An unexpected connection error occurred. Please try back later."
  }
});

export const userChangePassword = () => ({
  200: {
    severity: "success",
    response: "Password has been changed successfully!"
  },
  504: {
    severity: "error",
    response: "An unexpected connection error occurred. Please try back later."
  },
  "400: You cannot reuse that password.": {
    severity: "error",
    response: "Cannot reuse old passwords, for security."
  },
  "400: Your entered an incorrect old password.": {
    severity: "error",
    response: "Your current password must be correct to continue."
  },
  "400: New password does not meet password strength requirements.": {
    severity: "error",
    response: "Your new password was not strong enough."
  }
});

export const deeplinkLogin = (config) => ({
  default: {
    severity: "error",
    response: `We're unable to access your information. Please contact ${config?.brand_name} at ${config?.brand_phone}.`
  },
  404: {
    severity: "error",
    response: `We're unable to access your information. Please contact ${config?.brand_name} at ${config?.brand_phone}.`
  },
  504: {
    severity: "error",
    response: "An unexpected connection error occurred. Please try back later."
  },
  "Could not authenticate user data": {
    severity: "error",
    response: "We could not verify your information. Please try again."
  }
});

export const putAutopayResponses = (config) => ({
  default: {
    severity: "error",
    response: `We're unable to access your information. Please contact ${config?.brand_name} at ${config?.brand_phone}.`
  },
  200: {
    severity: "success",
    response: "Your autopay has been updated successfully!"
  },
  "Autopay account referenced by account_id is not owned by user.": {
    severity: "error",
    response: "We could not verify your information. Please log out and try again."
  },
  "Not a valid autopay ending.": {
    severity: "error",
    response: "Not a valid end date option, please select an available end date option."
  },
  "Not a valid autopay frequency.": {
    severity: "error",
    response: "Not a valid frequency, please select an available frequency."
  },
  "Not a valid payment_amount.": {
    severity: "error",
    response: "Not a valid payment amount, please enter a valid payment amount."
  },
  "Not a valid autopay starting_date.": {
    severity: "error",
    response: "Not a valid starting date, please enter a valid starting date."
  },
  "Not a valid autopay ending_date.": {
    severity: "error",
    response: "Not a valid ending date, please enter a valid ending date."
  }
});

export const deleteAutopayResponses = () => ({
  default: {
    severity: "error",
    response: "There was a problem canceling your scheduled payment."
  },
  200: {
    severity: "success",
    response: "Your autopay has been deleted successfully!"
  }
});

export const postSaveAccountResponses = () => ({
  default: {
    severity: "error",
    message: "There was a problem saving your account."
  },
  200: {
    severity: "success",
    message: "Account successfully added!"
  },
  "Insufficient Access": {
    severity: "error",
    message: "You are not authorized to delete that account."
  },
  "id is not allowed on POST request": {
    severity: "error",
    message: "There was a problem saving your account."
  },
  "Account type cannot be empty": {
    severity: "error",
    message: "Account type cannot be empty."
  },
  "Last 4 cannot be empty": {
    severity: "error",
    message: "Last 4 cannot be empty."
  },
  "Account number cannot be empty": {
    severity: "error",
    message: "Account number cannot be empty."
  },
  "Account banking type cannot be empty": {
    severity: "error",
    message: "Account banking type cannot be empty."
  },
  "validating account": {
    severity: "error",
    message: "Bad request."
  },
  "User not found": {
    severity: "error",
    message: "User not found!"
  },
  "Institution not found": {
    severity: "error",
    message: "Institution not found!"
  }
});

export const patchSaveAccountResponses = () => ({
  default: {
    severity: "error",
    response: "There was a problem updating your account."
  },
  "Name on account cannot be empty": {
    severity: "error",
    response: "Name on account cannot be empty, please enter your first and last name."
  }
});

export const deleteSavedAccountResponses = () => ({
  default: {
    severity: "error",
    message: "There was a problem deleting your account."
  },
  200: {
    severity: "success",
    message: "Account successfully deleted!"
  },
  "400: Invalid ID": {
    severity: "error",
    message: "You are not authorized to delete that account."
  },
  "401: Insufficient access": {
    severity: "error",
    message: "This account has insufficient access to delete this account"
  },
  "400: the account was not found": {
    severity: "error",
    message: "That account was not found."
  },
  "403: Forbidden": {
    severity: "error",
    message: "Could not add that account to this user."
  },
  "401: as the account is associated with an active autopay.  Please update the autopay prior to deleting this account.": {
    severity: "error",
    message: "As the account is associated with an active autopay. Please update the autopay prior to deleting this account."
  }
});

export const putStateOfResidenceResponses = (config) => ({
  default: {
    severity: "error",
    response: `We're unable to access your information. Please contact ${config?.brand_name} at ${config?.brand_phone}.`
  },
  200: {
    severity: "success",
    response: "Your state of residence has updated successfully!"
  },
  "400: state_of_residence is invalid": {
    severity: "error",
    response: "The value you provided is invalid, please provide a valid state of residence!"
  }
});

export const postGuest = () => ({
  default: {
    severity: "error",
    response: "Could not authenticate user data, please contact your financial institution"
  },
  400: {
    severity: "error",
    response: "Could not authenticate user data, please contact your financial institution"
  },
  "400: 'id' is not allowed on POST requests": {
    severity: "error",
    response: "Could not authenticate user data, please contact your financial institution"
  },
  "400: Must provide institution_id": {
    severity: "error",
    response: "Could not authenticate user data, please contact your financial institution"
  },
  "400: No security answer 1 present": {
    severity: "error",
    response: "No answer to security answer 1"
  },
  "400: Invalid Request: Institution not found": {
    severity: "error",
    response: "Institution not found"
  },
  "404: Could not authenticate user data, please contact your financial institution": {
    severity: "error",
    response: "Could not authenticate user data, please contact your financial institution"
  },
  "400: Invalid submission": {
    severity: "error",
    response: "Invalid submission"
  },
  "403: Could not authenticate user data": {
    severity: "error",
    response: "We could not verify your information. Please try again."
  },
  "409: User with that email already exists.": {
    severity: "error",
    response: "An account with that email exists already."
  }
});

export const promoteApiResponses = () => ({
  default: {
    severity: "error",
    response: "We could not verify your information. Please try again."
  },
  "400: Invalid security answer.": {
    severity: "error",
    response: "Your security answer was incorrect."
  },
  "403: jwt is expired": {
    severity: "error",
    response: "This session is expired. Please exit and return."
  },
  "403: jwt expired": {
    severity: "error",
    response: "This session is expired. Please exit and return"
  },
  "409: User with that email already exists.": {
    severity: "error",
    response: "An account with that email exists already."
  },
  400: {
    severity: "error",
    response: "You did not submit a valid request."
  },
  403: {
    severity: "error",
    response: "Malformed or invalid reset link. Request a new one."
  },
  409: {
    severity: "error",
    response: "Malformed or invalid reset link. Request a new one."
  }
});

export const reviewPaymentResponses = (config) => ({
  "we are unable to process payment with address information provided": {
    severity: "error",
    response: "Sorry, we are not able to process your payment because of the invalid billing address information. "
      + "Please go back to Accounts and Loans page and initiate a payment."
  },
  "not_checked": {
    severity: "error",
    response: AGREE_TERMS
  },
  "400: as a valid email address was not provided with your account": {
    severity: "error",
    response: "A valid email address was not provided with your account."
  },
  "400: as the payment account belongs to another member": {
    severity: "error",
    response: "Payment account belongs to another member."
  },
  "400: as the loan is not active": {
    severity: "error",
    response: "The loan is not active."
  },
  "400: as the minimum payment amount is $1.00": {
    severity: "error",
    response: "The minimum payment amount is $1.00."
  },
  "400: as the payment type is invalid": {
    severity: "error",
    response: "The payment type is invalid."
  },
  "400: because of an invalid credit card verification number": {
    severity: "error",
    response: "Sorry, we were unable to process your payment because of an invalid credit card verification number. "
      + "Please go back to Accounts page and initiate a payment."
  },
  "400: Card authorization error.": {
    severity: "error",
    response: "Sorry, we are not able to process your card payment because of authorization error. "
      + "Please go back to Accounts page and initiate a payment."
  },
  "400: as transaction exceeds user's maximum transaction dollar limit": {
    severity: "error",
    response: "Sorry, we are not able to process your payment as transaction exceeds user's maximum transaction dollar limit. "
      + "Please go back to Accounts page and initiate a payment."
  },
  "400: as the account was not found": {
    severity: "error",
    response: "The account was not found."
  },
  "400: name on account cannot be empty": {
    severity: "error",
    response: "Name on account cannot be empty."
  },
  "400: made with a credit card. Please use an alternative form of payment, such as a debit card or a bank account": {
    severity: "error",
    response: "Loan payments cannot be made with a credit card. Please use an alternative form of payment, such as a debit card or a bank account."
  },
  "400: as the credit card type is invalid for this account, please use a different card": {
    severity: "error",
    response: "Loan payments cannot be made with a credit card. Please use an alternative form of payment, such as a debit card or a bank account."
  },
  "400: Credit Card Type invalid for account – Please enter a Valid Debit Card Number or Use a Bank Account.": {
    severity: "error",
    response: "Loan payments cannot be made with a credit card. Please use an alternative form of payment, such as a debit card or a bank account."
  },
  "400: Exceeds maximum number of transactions for this calendar month": {
    severity: "error",
    response: "The transaction is unable to be processed at this time. This apply to account number has exceeded the allowable number of "
      + `transactions per calendar month. Please call ${config.brand_phone || "your Financial Institution"} for assistance.`
  },
  "400: Transaction date exceeds max post days": {
    severity: "error",
    response: "The transaction is not allowed for selected date. Please select a different payment date."
  },
  "400: as the card provided is invalid for this transaction, please use a different card": {
    severity: "error",
    response: "Loan payments cannot be made with a credit card. Please use an alternative form of payment, such as a debit card or a bank account."
  },
  "401: insufficient access": {
    severity: "error",
    response: "Insufficient access"
  },
  "403: as authentication failed": {
    severity: "error",
    response: "Authentication failed"
  },
  "403: Authentication failed": {
    severity: "error",
    response: "Authentication failed"
  },
  "403: as the account belongs to another member": {
    severity: "error",
    response: "The account belongs to another member."
  },
  "400: Internal error.": {
    severity: "error",
    response: "Sorry, we were unable to authorize the payment. Please try again or choose another payment method."
  },
  400: {
    severity: "error",
    response: UNKNOWN_ERR
  },
  403: {
    severity: "error",
    response: UNKNOWN_ERR
  },
  504: {
    severity: "error",
    response: "Encountered server error. Please try again."
  },
  default: {
    severity: "error",
    response: UNKNOWN_ERR
  }
});

export const postUniqueIdLookupResponses = () => ({
  default: {
    severity: "error",
    response: "There was a problem looking up your ID."
  }
});

export const registerNonValidationUserResponses = () => ({
  default: {
    severity: "error",
    response: "We could not verify your information. Please try again."
  },
  "409: User with that email already exists.": {
    severity: "error",
    response: "An account with that email exists already."
  },
  "400: email address provided is invalid.": {
    severity: "error",
    response: "Provided email is invalid."
  }
});
