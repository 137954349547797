import React, { useState, useEffect, useRef } from "react";
import { AddCircleOutline as AddCircleOutlineIcon } from "@mui/icons-material";
import { Grid, Stack, Link } from "@mui/material";
import { AlertDialog } from "@swbc/swivel-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Alert, AlertTitle, Box, Divider, Typography, Button } from "../../components/mui";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import { StyledDivider } from "../../components/styled/StyledDivider";
import Title from "../../components/Title";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import { clearAuthStateOnLogout } from "../../store/auth/auth.slice";
import { getLoans, resetAutopayApiResponses } from "../../store/loans/loans.slice";
import { clearIdempotency, clearLoanState, getIdempotencyToken, clearPaymentForm } from "../../store/payment/makePayment.slice";
import { clearGetAccountsErrors, getSavedAccounts } from "../../store/savedAccounts/savedAccounts.slice";
import { REGISTERED, NO_ACCOUNTS_INFO } from "../../utils/constants/constants";
import AccountCard from "./AccountCard";
import NonValidationAccountCard from "./NonValidationAccountCard";

const Accounts = () => {
  // Named selectors
  const institutionState = (state) => state.institution;
  const loanState = (state) => state.loans;
  const authState = (state) => state.auth;
  const paymentState = (state) => state.payment;

  // Pass in named selectors and gets state from redux
  const { list: loans, isLoading } = useSelector(loanState);
  const { config, details } = useSelector(institutionState);
  const { authToken, flow, user } = useSelector(authState);
  const { idempotency } = useSelector(paymentState);

  // Hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { trackEvent, actions, categories } = useGoogleAnalytics();

  // Local State
  const [openModal, setOpenModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageSeverity, setMessageSeverity] = useState("");
  const autoPayMessageRef = useRef(location.state?.autoPayMessage);

  const isProvisionalUser = user?.role === "provisional-user";
  const isApprovedUser = user?.role === "approved-user";
  const isApprovedUserNoLoans = isApprovedUser && loans?.length === 0 && !isLoading;
  const provisionalUserNoLoansMessage = " Your account is still pending approval. You will be able to add loans to pay on this " +
    "screen when your account is approved by your financial institution.";

  useEffect(() => {
    dispatch(resetAutopayApiResponses());
    dispatch(clearGetAccountsErrors());
    dispatch(clearPaymentForm());
    dispatch(clearIdempotency());
    dispatch(clearLoanState());
    dispatch(getIdempotencyToken(authToken.value));
  }, []);

  useEffect(() => {
    if (flow === REGISTERED && authToken.value) {
      dispatch(getSavedAccounts(authToken.value));
    }
  }, [authToken.value, flow, dispatch]);

  useEffect(() => {
    const popupDisplayed = sessionStorage.getItem("popupDisplayed");
    if (isApprovedUserNoLoans && !popupDisplayed) {
      setOpenModal(true);
      sessionStorage.setItem("popupDisplayed", "true");
    }
  }, []);

  useEffect(() => {
    if (!loans || loans.length === 0) {
      dispatch(getLoans(authToken.value))
        .unwrap()
        .then(res => {
          const hasLoans = res?.results && res.results.length > 0;
          if (!hasLoans) {
            setNoLoansFound();
          }
        })
        .catch(() => {
          setNoLoansFound();
        });
    }
  }, []);

  const setNoLoansFound = () => {
    setAlertMessage(NO_ACCOUNTS_INFO);
    setMessageSeverity("info");
  };

  const logout = () => {
    dispatch(clearAuthStateOnLogout());
  };

  const headerStyles = {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between"
  };

  const linkStyles = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.3rem",
    fontWeight: "bold"
  };

  return (
    isLoading || idempotency?.isSubmitting
      ?
      (
        <Box role="group">
          <Box sx={{ position: "relative", height: "5rem" }}>
            <StyledCircularProgress size={"2.5rem"} />
          </Box>
          <Typography variant="h1" sx={{ textAlign: "center" }}>
            <output aria-live="assertive">Loading account loans...</output>
          </Typography>
        </Box>
      )
      :
      (
        <>
          {isApprovedUserNoLoans ?
            <AlertDialog
              open={openModal}
              title="Welcome Back!"
              content="It looks like you are ready to add a new account to start making payments. Lets get started with adding an account or loan."
              primaryButtonText="Add account"
              secondaryButtonText="Close"
              onConfirm={() => {
                trackEvent(actions.BUTTON_CLICK, categories.NON_VALIDATION_ADD_ACCOUNT);
                navigate("/add-accounts");
              }}
              handleClose={() => setOpenModal(false)}
            /> : null}
          {isApprovedUser
            ?
            <>
              <Box style={headerStyles}>
                <Typography variant="h1" component="h1" flexGrow={1}>Accounts</Typography>
                <Link
                  style={linkStyles}
                  variant="body2"
                  underline="none"
                  component="button"
                  onClick={() => {
                    trackEvent(actions.BUTTON_CLICK, categories.NON_VALIDATION_ADD_ACCOUNT);
                    navigate("/add-accounts");
                  }}
                >
                  <AddCircleOutlineIcon />
                  add or edit accounts
                </Link>
              </Box>
              <StyledDivider aria-hidden="true" />
            </>
            :
            <Title title="Accounts" />
          }
          <Alert
            message={isProvisionalUser ? <AlertTitle>{alertMessage}</AlertTitle> : alertMessage}
            subTitle={isProvisionalUser && provisionalUserNoLoansMessage}
            severity={messageSeverity || "error"}
          />
          <Alert message={autoPayMessageRef.current} severity="success" />
          {
            loans &&
            <Stack
              divider={<Divider sx={{ width: "100%", margin: "auto" }} aria-hidden="true" />}
              sx={{ margin: "0px", padding: "0px", gap: "1rem", display: "grid" }}
            >
              {
                [...loans].sort((a, b) => a.number - b.number)
                  .map((result, index, array) => (
                    /* TODO: update so null is returned on false */
                    result.inactive
                      ? null
                      : (
                        <React.Fragment key={array[index].id}>
                          {isApprovedUser ?
                            <NonValidationAccountCard
                              config={config}
                              data={result}
                              details={details}
                              id={`Account-${array[index].id}`}
                              idempotencyToken={idempotency.token}
                              flow={flow}
                            /> :
                            <AccountCard
                              config={config}
                              data={result}
                              details={details}
                              id={`Account-${array[index].id}`}
                              idempotencyToken={idempotency.token}
                              flow={flow}
                            />}
                        </React.Fragment>
                      )
                  ))
              }
            </Stack>
          }
          {
            isProvisionalUser &&
            <Grid item xs={12} container direction="row-reverse">
              <Grid item xs={12} md={6}>
                <Box sx={{ position: "relative", marginTop: "20px" }}>
                  <Button
                    role="button"
                    color="primary"
                    fullWidth
                    size="large"
                    variant="contained"
                    aria-label="logout"
                    onClick={() => logout()}
                  >
                    Logout
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
        </>
      )
  );
};

export default Accounts;
