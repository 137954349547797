import React, { useState, useEffect, useMemo, useContext } from "react";
import { FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { Button, Typography } from "@swbc/swivel-components";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Checkbox, Alert } from "../../../components/mui";
import Title from "../../../components/Title";
import { transactionTypes, useTransactionContext, useTransactionDispatch } from "../../../context";
import { useGoogleAnalytics } from "../../../services/analytics/hooks/useGoogleAnalytics";
import { postV2 } from "../../../services/payments/api/postV2";
import { postVendor } from "../../../services/payments/api/postVendor";
import { constructACHPayload } from "../../../services/payments/lib/constructACHPayload";
import { constructApplePayPayload } from "../../../services/payments/lib/constructApplePayPayload";
import { constructCardPayload } from "../../../services/payments/lib/constructCardPayload";
import { SwivelContext } from "../../../services/sdk/context/SwivelProvider";
import { REGISTERED } from "../../../utils/constants/constants";
import { currentDate, getNextBankingDay } from "../../../utils/helpers/dayjsHelpers";
import { useNonBankingDays } from "../../../utils/hooks/useNonBankingDays";
import ApplePayButton from "./components/ApplePayButton";
import Disclosure from "./components/Disclosure";
import PaymentSummary from "./components/Summary";

// TODO:
// All values to be populate from state
// The breadcrumb is bound to change when the page is functionalP
// When the isPaymentMethodApplePay boolean is available, include the Apple pay button

const ReviewPayment = () => {
  const [termsChecked, setTermsChecked] = useState(false);
  const [parentOrigin, setParentOrigin] = useState("");
  const [applePayError, setApplePayError] = useState({});

  const paymentState = (state) => state.payment;
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;
  const nonBankingDaysState = (state) => state.nonBankingDays;

  const { response } = useSelector(nonBankingDaysState);
  const nonBankingDays = useNonBankingDays(response?.value);
  const { loan } = useSelector(paymentState);
  const { config, details } = useSelector(institutionState);
  const { authToken, flow } = useSelector(authState);
  const { paymentDetails } = useTransactionContext();
  const { trackEvent, categories, gaPaymentTypeEvent } = useGoogleAnalytics();
  const navigate = useNavigate();
  const dispatch = useTransactionDispatch();
  const { watch, register, handleSubmit, formState: { errors } } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      termsCheckbox: false
    }
  });
  const { embedded } = useContext(SwivelContext);
  const watchTermsCheckbox = watch("termsCheckbox", false);
  const displayPaymentDate = getNextBankingDay(nonBankingDays, details.daily_cutoff_time);

  const isApplePayEnabled = config?.services?.digital_wallets?.apple_pay?.enabled
    && window.ApplePaySession && window.ApplePaySession.canMakePayments();
  const isPaymentMethodApplePay = paymentDetails?.selectedPaymentMethod?.type === "apple" && isApplePayEnabled;

  const selectedPaymentType = paymentDetails?.selectedPaymentMethod?.type;
  const isCardPayment = selectedPaymentType === "card";
  const last4 = paymentDetails?.selectedPaymentMethod?.last_4;
  const isSelectedDate = flow === REGISTERED ? !isCardPayment : false;

  let accountVerbiage;
  if (selectedPaymentType === "apple") {
    accountVerbiage = "from your mobile digital wallet";
  } else {
    accountVerbiage = isCardPayment ? `from your card ending in ${last4}` : `from your account ending in ${last4}`;
  }

  const mutation = useMutation({
    mutationFn: postV2,
    onSuccess: (data) => {
      if (data?.PendingTransactions) {
        dispatch({ type: transactionTypes.UPDATE_PENDING_TRANSACTIONS, value: data?.PendingTransactions });
        navigate("/pending-payments");
      } else {
        dispatch({ type: transactionTypes.UPDATE_CONFIRMATION, value: { number: data?.tracking, time: currentDate } });
        trackEvent(gaPaymentTypeEvent(selectedPaymentType, true), categories.ATTEMPT_SUCCESS);
        navigate("/payment-confirmed");
      }
    },
    onError: (error) => {
      trackEvent(gaPaymentTypeEvent(selectedPaymentType, true), categories.ATTEMPT_FAILURE);
      throw new Error(error);
    }
  });

  const applePayMutation = useMutation({
    mutationFn: postVendor,
    onSuccess: (data) => {
      if (data?.PendingTransactions) {
        dispatch({ type: transactionTypes.UPDATE_PENDING_TRANSACTIONS, value: data?.PendingTransactions });
        navigate("/pending-payments");
      } else {
        dispatch({ type: transactionTypes.UPDATE_APPLE_PAY_PAYMENT_DETAILS, value: {} });
        dispatch({ type: transactionTypes.UPDATE_CONFIRMATION, value: { number: data?.tracking, time: currentDate } });
        trackEvent(gaPaymentTypeEvent(selectedPaymentType, true), categories.ATTEMPT_SUCCESS);
        navigate("/payment-confirmed");
      }
    },
    onError: () => {
      trackEvent(gaPaymentTypeEvent(selectedPaymentType, true), categories.ATTEMPT_FAILURE);
    }
  });

  const payload = isCardPayment
    ? constructCardPayload(paymentDetails, loan, authToken?.value, config)
    : constructACHPayload(paymentDetails, loan?.id, authToken?.value, loan?.idempotencyToken, flow, paymentDetails?.convenienceFee, config);

  const submitPayment = () => mutation.mutate(payload);

  const cardTypes = {
    "amex": "American Express",
    "visa": "Visa",
    "masterCard": "Mastercard",
    "discover": "Discover"
  };

  const supportedCardNetworks = ["amex", "visa", "masterCard", "discover"]
    .filter((cardType) => details.accepted_card_types.includes(cardTypes[cardType]));

  const supportedCardCapabilities = ["supports3DS", "supportsCredit", "supportsDebit"]
    .filter((capability) => capability !== "supportsCredit" || ["checking", "savings"].includes(loan?.type?.toLowerCase()));

  const paymentInfo = useMemo(() => ({
    total: (+paymentDetails.amount + +paymentDetails.convenienceFee).toFixed(2),
    amount: (+paymentDetails.amount).toFixed(2),
    fee: (+paymentDetails.convenienceFee).toFixed(2),
    label: loan.type,
    currency: "USD",
    countryCode: "US",
    merchantIdentifier: details?.services?.digital_wallets?.apple_pay.partner_internal_merchant_identifier,
    supportedCardNetworks,
    supportedCardCapabilities
  }), [details, loan, paymentDetails, supportedCardCapabilities, supportedCardNetworks]);

  const sendMessageToDigitalWallets = (data) => {
    data.origin = `https://${window.location.hostname}`;
    const digitalWalletsCustomEvent = new CustomEvent("message", { detail: data });
    window.dispatchEvent(digitalWalletsCustomEvent);
  };

  // event listener for digital-wallets.js

  useEffect(() => {
    // Event listener for iframe implementation
    const swivelPayListener = (event) => {
      const targetDocument = (event.target || event.currentTarget).document;
      if (event.data?.message?.includes("digital-wallets.js") && event.origin === targetDocument.location.ancestorOrigins[0]) {
        setParentOrigin(event.origin);
        event.source.postMessage({
          type: "ApplePayPaymentRequest",
          paymentInfo,
          token: authToken.value
        }, event.origin);
      }

      if (parentOrigin === event.origin) {
        if (event.data.type === "PaymentRequest" || event.data.type === "ApplePaySession") {
          if (event.data.isSuccess) {
            dispatch({ type: transactionTypes.UPDATE_APPLE_PAY_PAYMENT_DETAILS, value: event.data.payment });
            const payload = constructApplePayPayload(paymentDetails, loan, authToken.value, event.data.payment, details);
            applePayMutation.mutate(payload);
          } else {
            // show error message
            setApplePayError({ message: "Apple Pay closed.", severity: "info" });
          }
        } else if (!event.data?.message?.includes("digital-wallets.js")) {
          setApplePayError({
            message: "There was a problem contacting Apple. Please try again later or another payment method.",
            severity: "error"
          });
        }
      }
    };
    // Event listener for non-iframe / new-tab / redirect implementation
    const swivelPayInAppListener = (event) => {
      if (event.detail?.message?.includes("digital-wallets.js in app")) {
        const message = {
          type: "WebPageApplePayPaymentRequest",
          paymentInfo,
          token: authToken.value
        };
        sendMessageToDigitalWallets(message);
      }
      if (event.detail.type === "PaymentRequest" || event.detail.type === "ApplePaySession") {
        if (event.detail.isSuccess) {
          dispatch({ type: transactionTypes.UPDATE_APPLE_PAY_PAYMENT_DETAILS, value: event.detail.payment });
          const payload = constructApplePayPayload(paymentDetails, loan, authToken.value, event.detail.payment, details);
          applePayMutation.mutate(payload);
        } else {
          // show error message
          setApplePayError({ message: "Apple Pay closed.", severity: "info" });
        }
      } else if (!event.detail?.message?.includes("digital-wallets.js in app")) {
        setApplePayError({
          message: "There was a problem contacting Apple. Please try again later or another payment method.",
          severity: "error"
        });
      }
    };
    if (typeof window.addEventListener !== "undefined") {
      window.addEventListener("message", swivelPayListener);
      window.addEventListener("InAppDigitalWallets", swivelPayInAppListener);
    }
    return () => {
      if (typeof window.removeEventListener !== "undefined") {
        window.removeEventListener("message", swivelPayListener);
        window.removeEventListener("InAppDigitalWallets", swivelPayInAppListener);
      }
    };
  }, [authToken, parentOrigin, paymentInfo, applePayMutation, navigate]);


  const handleApplePayClick = () => {
    setApplePayError({});
    if (!embedded) {
      // create a custom event for in app communication (handshake)
      const handShakeMessage = { message: "From Swivel Pay Web Page" };
      sendMessageToDigitalWallets(handShakeMessage);
    } else {
      window.parent.postMessage({ message: "From Swivel Pay" }, "*");
    }
  };

  return (
    <>
      <Title
        title="Review Payment"
        subTitle="Here are your payment details"
        showDivider={false}
        sx={{ marginTop: "1rem" }}
      />
      <Alert message={mutation?.isError && mutation?.error?.response} severity={mutation?.error?.severity} />
      <Alert message={applePayMutation?.isError && applePayMutation?.error?.response} severity={applePayMutation?.error?.severity} />
      <Alert message={applePayError?.message} severity={applePayError?.severity} />
      <form id="review-form" onSubmit={handleSubmit(handleApplePayClick)}>
        <Grid
          container
          direction="column"
          columns={{ xs: 12, md: 12 }}
          spacing={1}
          component="fieldset"
          sx={{ border: "none", padding: "0px", marginTop: "1rem" }}
        >
          <Grid item xs={12}>
            <PaymentSummary
              isSelectedDate={isSelectedDate}
              paymentDetails={paymentDetails}
              loan={loan}
              displayPaymentDate={displayPaymentDate}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "grid", gap: "1rem" }}>
            <Disclosure
              institutionDetails={details}
              isSelectedDate={isSelectedDate}
              paymentDetails={paymentDetails}
              loan={loan}
              accountVerbiage={accountVerbiage}
              displayPaymentDate={displayPaymentDate}
            />
          </Grid>
          <Grid item sx={{
            textAlign: {
              xs: "left",
              md: "center"
            }
          }}>
            <Grid item sx={{ textAlign: "center" }}>
              <FormControl error={!!errors?.termsCheckbox}>
                <FormControlLabel
                  {...register("termsCheckbox", {
                    required: "Please confirm you agree to the payment terms"
                  })}
                  aria-label="Click check mark box to agree to payment terms."
                  control={<Checkbox checked={watchTermsCheckbox || false} onClick={() => setTermsChecked(!termsChecked)} />}
                  label={<Typography> I agree to the above Payment Terms </Typography>}
                />
                <FormHelperText>{errors?.termsCheckbox?.message || " "}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "1rem", flexDirection: "row-reverse" }}>
          {isPaymentMethodApplePay ?
            <Grid item xs={12} md={6}>
              <Box sx={{ position: "relative" }} gridArea="button" >
                <ApplePayButton
                  loading={applePayMutation.isLoading}
                  aria-label="Pay with Apple Pay"
                  form="review-form"
                  type="submit"
                />
              </Box>
            </Grid>
            :
            <Grid item xs={12} md={6}>
              <Box sx={{ position: "relative" }} gridArea="button">
                <Button
                  color="primary"
                  loading={mutation.isLoading}
                  fullWidth
                  disabled={!termsChecked}
                  onClick={handleSubmit(submitPayment)}
                >
                  CONTINUE TO PAY
                </Button>
              </Box>
            </Grid>
          }
          <Grid item xs={12} md={6}>
            <Box sx={{ position: "relative" }} gridArea="button">
              <Button
                color="secondary"
                fullWidth
                onClick={() => {
                  trackEvent(gaPaymentTypeEvent(selectedPaymentType, true), categories.ATTEMPT_ABANDONED);
                  navigate("/accounts");
                }}
              >
                Cancel payment
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ReviewPayment;
