import axios from "axios";
import { reviewPaymentResponses } from "../../../utils/data/responseCodeMessages";

const { REACT_APP_API_URL } = process.env;

export const postV2 = async (payload) => {
  const { token } = payload;
  const header = { headers: { Authorization: token } };
  const config = payload?.brandDetails;

  delete payload.token;
  delete payload.brandDetails;

  const res = await axios
    .post(`${REACT_APP_API_URL}/payment/v2`, payload, header)
    .then((response) => {
      if (response?.data?.tracking) {
        return response.data;
      }
      const { PendingTransactions } = response.data;
      PendingTransactions.forEach(val => payload.known_pending_transaction_numbers.push(val.TrackingNumber));
      return response.data;
    })
    .catch((error) => {
      const status = error?.response?.status || error?.request?.status;
      const data = error?.response?.data;
      const errorResponse = reviewPaymentResponses(config)[data.message]
        || reviewPaymentResponses(config)[status]
        || reviewPaymentResponses(config)["default"];
      throw errorResponse;
    });

  return res;
};
