import React, { useCallback, useEffect, useState } from "react";
import { OneTimePaymentForm } from "@swbc/swivel-components";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../../components/mui";
import Title from "../../../components/Title";
import {
  useSelectPaymentMethodContext,
  useTransactionContext,
  useTransactionDispatch,
  transactionTypes,
  selectPaymentMethodTypes,
  useSelectPaymentMethodDispatch
} from "../../../context";
import { useGoogleAnalytics } from "../../../services/analytics/hooks/useGoogleAnalytics";
import { useAchAccountUpdate } from "../../../services/payments/lib/hooks";
import { putStateOfResidence } from "../../../store/auth/auth.slice";
import { getConvenienceFeeMicroservice } from "../../../store/payment/makePayment.slice";
import { patchSaveAccount } from "../../../store/savedAccounts/savedAccounts.slice";
import { EXPRESS_PAY, REGISTERED } from "../../../utils/constants/constants";
import feeSources from "../../../utils/data/feeSources";
import { getNextBankingDay } from "../../../utils/helpers/dayjsHelpers";
import { getConvenienceFee, getPaymentConfig } from "../../../utils/helpers/getPaymentConfig";
import { getAccountLastFour } from "../../../utils/helpers/handleAccounts";
import { useNonBankingDays } from "../../../utils/hooks/useNonBankingDays";

const OneTime = () => {
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;
  const paymentState = (state) => state.payment;
  const savedAccountsState = (state) => state.savedAccounts;

  const { details } = useSelector(institutionState);
  const { loan, fee } = useSelector(paymentState);
  const { user, stateOfResidence: authStateOfResidence, flow, authToken } = useSelector(authState);
  const { card, ach, shouldDisableAmount } = getPaymentConfig(details, loan?.type);
  const { getAccounts } = useSelector(savedAccountsState);
  const { trackEvent, actions, gaPaymentTypeEvent } = useGoogleAnalytics();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { availablePaymentMethods } = useSelectPaymentMethodContext();
  const { paymentDetails } = useTransactionContext();
  const nonBankingDays = useNonBankingDays();
  const transactionDispatch = useTransactionDispatch();

  const stateOfResidenceError = authStateOfResidence?.api?.response?.severity === "error";
  const stateOfResidence = authStateOfResidence?.hasStateOfResidence && !paymentDetails?.stateOfResidence
    ? user?.state_of_residence
    : paymentDetails?.stateOfResidence;

  const disablePaymentDate = flow === EXPRESS_PAY;
  const disableStateOfResidence = !details?.services?.payments?.state_of_residence?.enabled;
  const isCardEnabled = details?.services?.payments?.card?.enabled;
  const isCardPayment = paymentDetails?.selectedPaymentMethod?.type === "card";
  const selectedPaymentType = paymentDetails?.selectedPaymentMethod?.type;
  let feeSource = feeSources.json;

  const isApplePayEnabled = isCardEnabled && details?.services?.digital_wallets?.apple_pay?.enabled
    && window.ApplePaySession && window.ApplePaySession.canMakePayments();

  // create local copy of available payment methods to mutate
  const [paymentMethods, setPaymentMethods] = useState([]);

  const selectPaymentDispatch = useSelectPaymentMethodDispatch();
  const achAccounts = useAchAccountUpdate({ getAccounts });

  // get ach accounts from API to show in dropdown
  useEffect(() => {
    if (achAccounts) {
      selectPaymentDispatch({
        type: selectPaymentMethodTypes.UPDATE_AVAILABLE_ACH,
        value: achAccounts
      });
    }
  }, [achAccounts]);


  useEffect(() => {
    if (availablePaymentMethods.achAccounts.length && !paymentMethods.find((val) => availablePaymentMethods.achAccounts[0].id === val.id)) {
      setPaymentMethods([...paymentMethods, ...availablePaymentMethods.achAccounts]);
    }

    return () => {
      () => setPaymentMethods([]);
    };
  }, [availablePaymentMethods.achAccounts]);

  useEffect(() => {
    if (isApplePayEnabled) {
      if (!paymentMethods.find((val) => val.type === "apple")) {
        setPaymentMethods([{ type: "apple", label: "Apple Pay" }, ...paymentMethods]);
      }
    }

    return () => {
      () => setPaymentMethods([]);
    };
  }, [isApplePayEnabled]);

  useEffect(() => {
    if (isCardPayment) {
      setPaymentMethods([...paymentMethods, paymentDetails?.selectedPaymentMethod]);
      transactionDispatch({
        type: transactionTypes.UPDATE_SELECTED_PAYMENT_METHOD,
        value: paymentDetails?.selectedPaymentMethod
      });
    }

    return () => {
      () => setPaymentMethods([]);
    };
  }, [isCardPayment]);

  const getConvenienceFeeCallback = useCallback((paymentType) => {
    const fee = getConvenienceFee(details, { loan: loan?.type, payment: paymentType });

    transactionDispatch({ type: transactionTypes.UPDATE_CONVENIENCE_FEE, value: fee });
    transactionDispatch({ type: transactionTypes.UPDATE_CONVENIENCE_FEE_SOURCE, value: feeSources.json });
  }, [details, loan?.type, selectedPaymentType, transactionDispatch]);

  const handleSubmit = (event) => {
    const transactionPayload = {
      type: transactionTypes.UPDATE_PAYMENT_DETAILS,
      value: {
        amount: event.data.payment_amount,
        nameOnAccount: event.data.name_on_account,
        stateOfResidence: event.data.state_of_residence,
        date: event.data.payment_date,
        selectedPaymentMethod: event.data.payment_select
      }
    };
    const account = achAccounts?.find(account => account.id === event.data?.payment_select?.id);
    const isAchPayment = event.data?.payment_select?.type.toLowerCase() === "ach";
    const isRegistered = flow === REGISTERED;
    const isNameChanged = isAchPayment && isRegistered && account?.banking_details?.name_on_account !== event.data.name_on_account;
    if (isNameChanged) {
      const accountPayload = {
        token: authToken?.value,
        id: event.data?.payment_select?.id,
        banking_details: {
          name_on_account: event.data.name_on_account
        }
      };
      transactionPayload.value.selectedPaymentMethod.banking_details.name_on_account = event.data.name_on_account;
      dispatch(patchSaveAccount(accountPayload));
    }
    const setAndUpdateDetails = () => {
      if (flow === EXPRESS_PAY || disableStateOfResidence) {
        transactionDispatch(transactionPayload);
        navigate("/review-payment");
      } else {
        const stateOfResidencePayload = {
          token: authToken?.value,
          user_id: user?.id,
          state_of_residence: event.data.state_of_residence,
          brandDetails: {
            brand_phone: details?.brand_phone,
            brand_name: details?.brand_name
          }
        };
        dispatch(putStateOfResidence(stateOfResidencePayload))
          .unwrap()
          .then((response) => {
            transactionPayload.value.stateOfResidence = response.state.state_of_residence;
            transactionDispatch(transactionPayload);
            navigate("/review-payment");
          });
      }
    };

    if (!details.portal_account_id) {
      getConvenienceFeeCallback(event.data.payment_select.type);
      setAndUpdateDetails();
      feeSource = feeSources.json;
      return;
    }

    const convenienceFeePayload = {
      transaction_amount: event.data.payment_amount,
      apply_to_type: [loan?.type],
      state_of_residence: event.data.state_of_residence,
      pay_from_type: isCardPayment ? "card" : "ach",
      portal_account_id: details?.portal_account_id,
      authToken: authToken?.value
    };

    dispatch(getConvenienceFeeMicroservice(convenienceFeePayload))
      .unwrap()
      .then((res) => {
        transactionDispatch({ type: transactionTypes.UPDATE_CONVENIENCE_FEE, value: res?.fee });
        feeSource = res.isDefault
          ? feeSources.microserviceDefault
          : feeSources.microserviceSchedule;
        transactionDispatch({ type: transactionTypes.UPDATE_CONVENIENCE_FEE_SOURCE, value: feeSource });
      })
      .catch(() => {
        getConvenienceFeeCallback();
        feeSource = feeSources.json;
        transactionDispatch({ type: transactionTypes.UPDATE_CONVENIENCE_FEE_SOURCE, value: feeSource });
      })
      .finally(() => {
        setAndUpdateDetails();
      });

    trackEvent(actions.BUTTON_CLICK, gaPaymentTypeEvent(event.data.payment_select.type));
  };

  const onClickAddOrEdit = (event) => {
    if (event) {
      transactionDispatch({
        type: transactionTypes.UPDATE_PAYMENT_DETAILS,
        value: {
          amount: event.payment_amount,
          nameOnAccount: event.name_on_account,
          stateOfResidence: event.state_of_residence,
          date: event.payment_date,
          selectedPaymentMethod: event.payment_select
        }
      });
    }
    navigate("/select-payment-method");
  };

  const oneTimePaymentFormDefaultValues = {
    "payment_amount": paymentDetails.amount ? paymentDetails.amount : loan?.next_amount_due?.toString(),
    "payment_date": paymentDetails.date,
    "name_on_account": paymentDetails.nameOnAccount || user?.account_name,
    "payment_select": paymentDetails?.selectedPaymentMethod ? paymentDetails?.selectedPaymentMethod : {},
    "state_of_residence": !disableStateOfResidence ? stateOfResidence : ""
  };

  const paymentFormProps = {
    onSubmit: handleSubmit,
    onClickAddOrEdit,
    paymentMethods,
    nonBankingDays,
    hideSecondaryButton: true,
    disableGutters: true,
    options: {
      achEnabled: details?.services?.payments?.ach?.enabled,
      cardEnabled: details?.services?.payments?.card?.enabled,
      disablePaymentAmount: shouldDisableAmount,
      disableStateOfResidence,
      disablePaymentDate,
      cardLimits: {
        min: card.minCardAmount.toString(),
        max: card.maxCardAmount.toString()
      },
      achLimits: {
        min: ach.minAchAmount.toString(),
        max: ach.maxAchAmount.toString()
      },
      calendarProps: {
        maxDate: details?.max_post_days,
        minDate: dayjs(getNextBankingDay(nonBankingDays, details?.daily_cutoff_time), "MM/DD/YYYY")
      }
    },
    initialValues: oneTimePaymentFormDefaultValues,
    isLoading: authStateOfResidence?.api?.isSubmitting || fee?.isLoading
  };

  return (
    <>
      <Title title="Make A Payment" subTitle={`Pay account ending in ${getAccountLastFour(loan?.number)}`} />
      <Alert severity={stateOfResidenceError && authStateOfResidence?.api?.response?.severity}
        message={stateOfResidenceError && authStateOfResidence?.api?.response?.response} />
      <OneTimePaymentForm {...paymentFormProps} />
    </>
  );
};

export default OneTime;
