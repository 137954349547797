import React from "react";
import Title from "../../components/Title";

const NonValidationAddAccount = () => {
  return (
    <>
      <Title title="Add or Edit Accounts" />
    </>
  );
};

export default NonValidationAddAccount;
