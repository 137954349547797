import React, { useState, useEffect } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { SourceSelection, creditCardBrand, ApplePayIcon } from "@swbc/swivel-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Box } from "../../../components";
import Title from "../../../components/Title";
import {
  transactionTypes,
  selectPaymentMethodTypes,
  useSelectPaymentMethodContext,
  useTransactionDispatch,
  useSelectPaymentMethodDispatch,
  useTransactionContext
} from "../../../context";
import { useAchAccountUpdate } from "../../../services/payments/lib/hooks";
import { deleteSavedAccount, setProfileError, clearDeleteAccountErrors } from "../../../store/savedAccounts/savedAccounts.slice";
import { routeMap } from "../../../utils/data/routeMap";
import { getAccountLastFour } from "../../../utils/helpers/handleAccounts";

const SelectPaymentMethod = () => {
  const paymentState = (state) => state.payment;
  const institutionState = (state) => state.institution;
  const savedAccountsState = (state) => state.savedAccounts;
  const loansState = (state) => state.loans;
  const authState = (state) => state.auth;
  const [accountId, setAccountId] = useState(null);

  const { config, details } = useSelector(institutionState);
  const { loan } = useSelector(paymentState);
  const { getAccounts, deleteAccount } = useSelector(savedAccountsState);
  const { list } = useSelector(loansState);
  const { authToken } = useSelector(authState);

  const dispatch = useDispatch();

  const transactionDispatch = useTransactionDispatch();
  const { paymentDetails } = useTransactionContext();
  const selectPaymentDispatch = useSelectPaymentMethodDispatch();
  const { availablePaymentMethods } = useSelectPaymentMethodContext();

  const navigate = useNavigate();

  const onSelect = ({ type, selectedPaymentMethod }) => {
    if (selectedPaymentMethod) {
      transactionDispatch({
        type: transactionTypes.UPDATE_SELECTED_PAYMENT_METHOD,
        value: { type, ...selectedPaymentMethod?.details }
      });
    }

    if (type === "apple") {
      transactionDispatch({
        type: transactionTypes.UPDATE_SELECTED_PAYMENT_METHOD,
        value: { type: "apple", label: "Apple Pay" }
      });
    }
  };

  const handleDeleteAccount = (account) => {
    dispatch(clearDeleteAccountErrors());

    const activeLoans = list.filter((loan) =>
      loan.autopay?.account_id.includes(account?.id)
    );
    setAccountId(account?.id);
    if (activeLoans.length) {
      const linkedLoans = activeLoans.map(
        (loan) => `Ending in ${loan.number.slice(-4)}`
      );
      setTimeout(() => {
        dispatch(
          setProfileError({
            severity: "error",
            message: `Cannot delete, account is used for autopay loans: [${linkedLoans.join(", ")}]`
          })
        );
        setAccountId("");
      }, 1500);
    } else {
      dispatch(deleteSavedAccount({ id: account.id, token: authToken.value }));
      setAccountId(account?.id);
    }
  };

  useEffect(() => {
    dispatch(clearDeleteAccountErrors());
  }, []);

  const onClickContinue = () => navigate("/make-payment");

  const onClickAddBank = () => {
    const entryPoint = routeMap.get("/select-payment-method");
    selectPaymentDispatch({
      type: selectPaymentMethodTypes.SAVE_ENTRY_POINT,
      value: entryPoint
    });
    navigate("/connect-bank");
  };

  const onClickAddCard = () => navigate("/add-card");

  const isCardEnabled = details?.services?.payments?.card?.enabled || details?.card_payment_enabled;
  const isAchEnabled = details?.services?.payments?.ach?.enabled || details?.ach_payment_enabled;
  const isApplePayEnabled = isCardEnabled && config?.services?.digital_wallets?.apple_pay?.enabled
    && window.ApplePaySession && window.ApplePaySession.canMakePayments();

  const paymentMethods = [
    {
      id: 1,
      type: "card",
      label: "Card",
      icon: <CreditCardIcon />,
      enabled: isCardEnabled
    },
    {
      id: 2,
      type: "ach",
      label: "Bank",
      icon: <AccountBalanceIcon />,
      enabled: isAchEnabled
    },
    {
      id: 3,
      type: "apple",
      label: "Apple Pay",
      icon: <Box sx={{ display: "flex", marginBottom: "9px" }}><ApplePayIcon /></Box>,
      enabled: isApplePayEnabled
    }
  ];

  const cardList = availablePaymentMethods?.cards?.map((item) => {
    const cardBrand = creditCardBrand(item?.card_number);
    const lastFour = getAccountLastFour(item?.card_number);
    return {
      id: 1, // unique id when we have stored cards
      paymentType: "card",
      cardBrand,
      cardExpiry: item?.card_expiry_date,
      primaryText: cardBrand,
      secondaryText: lastFour,
      details: item
    };
  });

  const bankList = availablePaymentMethods?.achAccounts?.map((item) => {
    return {
      id: item?.id,
      paymentType: "ach",
      primaryText: item?.label,
      secondaryText: item?.last_4,
      details: item
    };
  });

  const paymentMethodDispatch = useSelectPaymentMethodDispatch();
  const achAccounts = useAchAccountUpdate({ getAccounts });

  useEffect(() => {
    if (achAccounts) {
      paymentMethodDispatch({
        type: selectPaymentMethodTypes.UPDATE_AVAILABLE_ACH,
        value: achAccounts
      });
    }
  }, [achAccounts]);

  return (
    <>
      <Title
        title="Add or Edit Payment Methods"
        subTitle={`Pay account ending in ${getAccountLastFour(loan?.number)}`}
      />
      <Alert message={deleteAccount?.response?.message} severity={deleteAccount?.response?.severity} />
      <SourceSelection
        onSelect={onSelect}
        onDelete={handleDeleteAccount}
        onClickAddBank={onClickAddBank}
        onClickAddCard={onClickAddCard}
        onClickContinue={onClickContinue}
        paymentMethods={paymentMethods}
        cardList={cardList}
        bankList={bankList}
        priority={paymentDetails?.selectedPaymentMethod?.type || config?.services?.payments?.priority}
        loadingItemId={accountId}
      />
    </>
  );
};

export default SelectPaymentMethod;
